module.exports = {
  methods: {
    getAuctionImageBackground: function (params) {
      return process.env.VUE_APP_server_URL + 'uploads/auctions/' + params;
    },
    getAuctionTypeName: function (params) {
      switch (params) {
        case 'quiet':
          return 'ТИХИЙ';


        case 'loud':
          return 'ГРОМКИЙ';


        default:
          break;
      }
    },
    returnBoolean: function (value) {
      if (value) return true;
      return false;
    },
    validatePhone: function (phone) {
      let phoneno = /\+\d{12}$/;
      if (phone.match(phoneno)) {
        return true;
      } else {
        return false;
      }
    },

  }



};
