<template>
  <div>
    <audio ref="audioElm" src="/static/sound/notify.mp3" preload="auto"></audio>
  </div>
</template>

<script>
import io from "socket.io-client";
import mixin from "@/mixins/bids";
import mixin2 from "@/mixins/lot";

export default {
  store: [
    "auction",
    "active_lots",
    "offline_bids",
    "reload_bids",
    "reload_data",
    "reload_members"
  ],
  mixins: [mixin, mixin2],
  data() {
    return {
      socket: io(
        process.env.VUE_APP_socket_server
        // {
        //   transports: ['websocket']
        // }
      )
    };
  },
  created() {
    console.log("ready");

    // this.socket.on('reconnect_attempt', () => {
    //   this.socket.io.opts.transports = ['polling', 'websocket'];
    // });

    this.socket.on("connect", function() {
      console.log("connected v.1.1", process.env.VUE_APP_socket_server);
      window.getApp.$emit("APP_SOCKET_CONNECTED");
    });

    this.socket.on("disconnect", function() {
      console.log("disconnected");
      window.getApp.$emit("APP_SOCKET_DISCONNECTED");
    });

    this.socket.on("reconnect_attempt", () => {
      console.log("reconnect");
      window.getApp.$emit("APP_BAD_REQUEST");
    });
  },
  mounted() {
    this.checkData();
  },
  methods: {
    checkData: function() {
      let self = this;
      console.log(process.env.VUE_APP_CHANNEL);
      this.socket.on(process.env.VUE_APP_CHANNEL, function(data) {
        self.$refs.audioElm.play();

        switch (data[0].event) {
          case "BidPlaced":
            console.log("bid placed");
            window.getApp.$emit("APP_NEW_BID");
            self.bidsRefresh(data[0].data.auction_slug, data[0].data.number);
            break;
          case "BidStatusUpdated":
            console.log("bid updated");
            window.getApp.$emit("APP_UPD_BID");
            self.bidsRefresh(data[0].data.auction_slug, data[0].data.number);
            break;
          case "LotStatusChanged":
            console.log("lot changed");
            window.getApp.$emit("APP_UPD_LOT");
            self.updateLot(
              data[0].data.auction_slug,
              data[0].data.id,
              data[0].data.state
            );
            break;
          case "AuctionMemberRegistered":
            console.log("member registered");
            window.getApp.$emit("APP_MEMBER_REGISTERED");
            self.reload_members = true;
            break;
          case "AuctionUserRegistration":
            console.log("member registered");
            window.getApp.$emit("APP_USER_REGISTERED");
            self.reload_members = true;
            break;
          case "AuctionUserVerified":
            console.log("member registered");
            window.getApp.$emit("APP_MEMBER_VERIFIED");
            self.reload_members = true;
            break;
          case "LotToggleOnline":
            window.getApp.$emit("APP_UPD_LOT");
            self.updateLot(
              data[0].data.auction_slug,
              data[0].data.id,
              data[0].data.state
            );
            break;
          // default:
          //   break;
        }
      });
    },
    refreshBidList() {
      console.log("refresh");
    }
  }
};
</script>
