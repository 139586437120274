<template>
  <v-card>
    <v-toolbar card dense color="transparent">
      <v-toolbar-title>
        <h4></h4>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>more_vert</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text class="pa-0" v-if="active_members">
      <template>
        <v-data-table
          :headers="headers"
          :items="active_members"
          hide-actions
          class="elevation-0 table-striped"
        >
          <template slot="items" slot-scope="props">
            <td>{{ props.item.pivot.number }}</td>
            <td class="text-xs-left">{{ props.item.title }}</td>
            <td class="text-xs-left" v-html="getContactInfo(props.item)"></td>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from '@/mixins/members';
export default {
  mixins: [mixin],
  store: ['auction'],
  data () {
    return {
      active_members: [],
      headers: [
        {
          text: 'Номер',
          align: 'left',
          sortable: true,
          value: 'id'
        },
        { text: 'Користувач', value: 'title', sortable: false },
        { text: 'Контактні данні', value: 'progress' },

      ],
      colors: {
        processing: 'blue',
        sent: 'red',
        delivered: 'green'
      }

    };
  },
  computed: {
    randomColor () {
      let item = Math.floor(Math.random() * this.colors.length);
      return this.colors[item];
    },
  },
  mounted () {
    // console.log('members ', this.getAuctionActiveMembers());
    this.active_members = this.getAuctionActiveMembers();
    // console.log('mounted', this.getActiveMembers());
  },
  methods: {
    getAuctionActiveMembers: function () {
      let auction = this.auction;
      let activeMembers = window._.filter(auction.members, function (member) {
        return window._.find(auction.all_lots, function (lot) {
          return window._.find(lot.bids, function (bid) {
            if (bid.member) {
              return bid.member.id === member.id;
            }

          });
        });
      });
      return activeMembers;
    },
    getColorByStatus (status) {
      return this.colors[status];
    },
    getContactInfo (item) {
      return 'Номер телефону:' + item.mobile_phone + '<br> Email:' + item.email;
    }
  }
};
</script>
