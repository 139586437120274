module.exports = {
  store: ['auction', 'members', 'reload_members'],
  methods: {
    memberLocation: function (member) {
      let location = member.pivot.location;

      return location;
    },
    getMemberLocationName: function (member) {
      let location = this.memberLocation(member);

      switch (location) {
        case 0:
          return 'Online';
        case 1:
          return 'У залі';
        case 2:
          return 'За телефоном';
        case 3:
          return 'Заочний бід';
        default:
          return 'Не визначено';
      }
    },
    getMembersList: function (slug) {
      this.loading = true;
      // GET /someUrl
      this.axios.get(process.env.VUE_APP_api_server_URL + 'v2/auctions/' + slug + '/members')
        .then(response => {
          // get body data
          let result = response.data;
          this.reload_members = false;
          this.members = result.members;
          this.loading = false;


        }, response => {
          console.log(response)
          // error callback
        });
    },
  }



};
