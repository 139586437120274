<template>
  <v-card>
    <v-toolbar card dense color="transparent">
      <v-toolbar-title>
        <h4></h4>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>more_vert</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="position: relative">
      <v-btn
        absolute
        dark
        fab
        top
        left
        color="pink"
        @click.native="addBid()"
        v-if="offline_bids.lot.closed == 0"
      >
        <v-icon>add</v-icon>
      </v-btn>
    </v-card-text>
    <v-card-text class="pa-0">
      <template>
        <v-data-table
          :headers="headers"
          :items="offline_bids.lot.bids"
          class="elevation-0 table-striped"
          :pagination.sync="pagination"
          :hide-headers="isMobile()"
        >
          <template slot="items" slot-scope="props">
            <td class="text-sm-left subheading">
              {{ '#'+ props.item.member.pivot.number }} - {{ props.item.value }}
              <v-icon
                small
                class="mr-2"
                @click="editBid(props.item,props.index)"
                v-if="props.item.member.pivot.location != 0 && lastBid().id == props.item.id"
              >edit</v-icon>
              <v-icon
                small
                class="mr-2"
                @click="deleteBid(props.item)"
                v-if="auction.type=='loud' && props.item.member.pivot.location == 0 && offline_bids.lot.closed == 0"
              >delete</v-icon>
            </td>
            <td class="text-xs-left hidden-sm-and-down">{{props.item.created_at}}</td>
            <td class="text-xs-left">{{ getLocationById(props.item.member.pivot.location)}}</td>
            <td
              class="text-xs-left"
              v-if="auction.type =='loud' && props.item.member.pivot.location == 0 && props.item.status == 0"
            >
              <v-btn fab small color="green">
                <v-icon @click=" acdcBid(props.item, 1)">check</v-icon>
              </v-btn>
              <v-btn fab color="red" small>
                <v-icon dark @click="acdcBid(props.item, 2)">close</v-icon>
              </v-btn>
            </td>
            <td
              class="text-xs-left"
              v-if="auction.type =='loud' && props.item.member.pivot.location == 0 && props.item.status != 0"
            >
              <v-chip>{{bidStatus(props.item.status)}}</v-chip>
            </td>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
      <placeBid ref="ActiveLotPlaceBid" :lot="offline_bids.lot"></placeBid>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "@/mixins/lot";
import mixin_bids from "@/mixins/bids";
import placeBid from "@/components/bid/dialogs/placeBid";

export default {
  store: ["auction", "offline_bids"],
  components: {
    placeBid
  },
  mixins: [mixin, mixin_bids],
  props: {
    current_lot: {
      type: Number
    }
  },
  data() {
    return {
      re_selected: false,
      pagination: {
        sortBy: "created_at",
        descending: true,
        rowsPerPage: 7
      },
      lot_number: null,
      headers: [
        {
          text: "Номер-Ставка",
          align: "left"
        },
        { text: "Час", sortable: false },
        { text: "Локація", sortable: false },
        { text: "Статус", sortable: false }
      ],
      colors: {
        processing: "blue",
        sent: "red",
        delivered: "green"
      }
    };
  },
  computed: {
    randomColor() {
      let item = Math.floor(Math.random() * this.colors.length);
      return this.colors[item];
    }
  },
  mounted() {
    // console.log(this.offline_bids.lot.bids);
  },
  methods: {
    isMobile: function isMobile() {
      return window.innerWidth < 993;
    },
    // filterItems (val, search) {
    //   console.log(val);
    // },
    addBid: function() {
      this.$refs.ActiveLotPlaceBid.update = false;
      this.$refs.ActiveLotPlaceBid.bid.value = 0;
      this.$refs.ActiveLotPlaceBid.openDialog();
    },
    editBid: function(bid, index) {
      this.$refs.ActiveLotPlaceBid.update = true;
      this.$refs.ActiveLotPlaceBid.lastBidIndex = index;
      this.$refs.ActiveLotPlaceBid.bid.value = 0;
      // this.$refs.ActiveLotPlaceBid.form_min_value = bid1value;
      this.$refs.ActiveLotPlaceBid.openDialog(bid);
    },
    deleteBid: function(bid) {
      let url = process.env.VUE_APP_api_server_URL + "v1/delete-bid/" + bid.id;
      let data = this.bid;

      this.axios.post(url, data).then(
        response => {
          this.offline_bids.lot = response.data.lot;
          let lotIndex = window._.findIndex(this.auction.all_lots, {
            id: response.data.lot.id
          });

          this.auction.all_lots[lotIndex] = this.offline_bids.lot;
          this.placedialog = false;
          // this.reload_bids = true;
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    },
    acdcBid: function(bid, status) {
      let urlParams = "";

      if (status == 1) {
        urlParams = "v1/accept-bid/";
      }
      if (status == 2) {
        urlParams = "v1/decline-bid/";
      }

      let url = process.env.VUE_APP_api_server_URL + urlParams + bid.id;
      let data = this.bid;

      this.axios.post(url, data).then(
        response => {
          // this.loadActiveBids();
          this.placedialog = false;
          // this.reload_bids = true;
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    },
    getColorByStatus(status) {
      return this.colors[status];
    },
    getLotImgSrc: function(item) {
      return process.env.VUE_APP_server_URL + item.thumb_dashboard;
    },
    getLotBidsInfo(item) {
      return "Номер телефону:" + item.mobile_phone + "<br> Email:" + item.email;
    }
  }
};
</script>
