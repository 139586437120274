<template>
  <v-app id="login" class>
    <v-content>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 lg4>
            <v-card class="elevation-1 pa-3">
              <v-card-text>
                <div class="layout column align-center">
                  <img src="/static/m.png" alt="Vue Material Admin" width="120" height="120" />
                  <h1 class="flex my-4 primary--text">Рабочее место менеджера</h1>
                </div>
                <v-form>
                  <v-text-field
                    append-icon="person"
                    name="email"
                    label="Email"
                    type="email"
                    v-model="model.email"
                  ></v-text-field>
                  <v-text-field
                    append-icon="lock"
                    name="password"
                    label="Password"
                    id="password"
                    type="password"
                    v-model="model.password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn block color="primary" @click="login" :loading="loading">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    model: {
      email: "",
      password: ""
    }
  }),
  mounted() {
    if (!this.$auth.check()) {
      // this.setIsLoaded(false)
    }
    if (this.$auth.redirect()) {
      // console.log('Redirect from: ' + this.$auth.redirect().from.name);
    }
    // Can set query parameter here for auth redirect or just do it silently in login redirect.
  },
  methods: {
    // login () {
    //   this.loading = true;
    //   setTimeout(() => {
    //     this.$router.push('/dashboard');
    //   }, 1000);
    // },
    login() {
      let redirect = this.$auth.redirect();
      this.$auth.login({
        headers: {
          // 'Content-Type': 'application/json'
          "Content-Type": "application/x-www-form-urlencoded"
        },
        data: "email=" + this.model.email + "&password=" + this.model.password,
        // rememberMe: this.data.rememberMe,
        redirect: { name: redirect ? redirect.from.name : "Auction" },
        success(res) {
          // console.log('Auth Success');
          // console.log(this.$auth);
          // console.log(res);
        },
        error(err) {
          if (err.response) {
            // The request was made, but the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(err.response.status);
            // console.log(err.response.data);
            // console.log(err.response.headers);
            this.error = err.response.data;
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", err.message);
          }
          console.log(err.config);
        }
      });
    }
  }
};
</script>
<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
