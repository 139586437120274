module.exports = {
  store: ['offline_bids', 'auction', 'active_tab', 'active_lots'],
  methods: {
    getActiveBidsList: function () {
      let bidsList = window._.filter(this.offline_bids.lot.bids, function (bid) {
        return bid.status != 2;
      });
      return bidsList;
    },
    getActiveOfflineBidsList: function () {
      let bidsList = window._.filter(this.offline_bids.lot.bids, function (bid) {
        return bid.status != 2 && bid.status != 0;
      });
      return bidsList;
    },
    getLotBidsList: function (lot) {
      let bidsList = window._.filter(lot.bids, function (bid) {
        return bid.status != 2;
      });
      return bidsList;
    },
    lastBid: function () {
      if (this.getActiveOfflineBidsList().length) {
        return window._.maxBy(this.getActiveOfflineBidsList(), 'value');
      } else {
        return null;
      }

    },
    lotLastBid: function (lot) {
      if (this.getLotBidsList(lot).length) {
        return window._.maxBy(this.getLotBidsList(lot), 'value');
      } else {
        return null;
      }

    },
    lastBidMember: function () {
      if (this.getActiveOfflineBidsList().length) {
        return window._.maxBy(this.getActiveOfflineBidsList(), 'member');
      } else {
        return null;
      }
    },
    getPervBid: function (bidId) {
      let bids = '';
      // if (this.auction.type == 'loud') {
      bids = this.getActiveBidsList();
      // }
      // bids = this.getLotBidsList(this.lot);

      let index = window._.findIndex(bids, {
        'id': bidId
      });

      return bids[index + 1];
    },
    lastBidInfo: function (lot) {
      let html = '';
      html = `<span>`;
      if (!lot.closed) html = html + '<strong class="title">' + this.lastBid(lot).value + '</strong>';
      if (lot.closed && lot.sell_price) {
        html = html + `
                        <span class="title">
                            Продано за ` + lot.sell_price +
          `</span>`;
      }
      html = html + '</span>';
      html = html + `
                    <div class="title">
                        Номер: ` + this.lotLastBid(lot).member.pivot.number + `
                    </div>
                    <div>
                        <small> Дата: ` + this.lotLastBid(lot).created_at + ` </small>
                    </div>`;
      return html;
    },
    getLotInfo: function (lot) {
      // console.log('lot', lot.authors.length);

      let html = '';
      if (lot.authors.length > 0) html = html + '<div  class="text-uppercase"><b>' + lot.authors[0].title + '.</b></div>';
      if (lot.title) html = html + '<span>' + lot.title + '.</span>';
      if (lot.year && lot.year !== 0) html = html + '<span  style="text-transform:none !important">' + lot.year + '&nbsp;р</span>';
      if (lot.closed) html = html + '<div class="text-danger text-uppercase">закрито</div>';
      html = html + `<div>
                        <small>
                            Стратова:
                            <b>` + lot.start_price + `</b>
                        </small>
                    </div>
                    <div>
                        <small>Кілкість ставок:` + lot.bids.length + `</small>
                    </div>`;

      if (this.auction.type !== 'loud' && lot.expiration) {
        html = html + `
                     <div >
                             <small>До завершення: ` +
          lot.expiration.days + `д` +
          lot.expiration.hour + `г` +
          lot.expiration.minutes + `х` +
          lot.expiration.seconds + `с</small>
                      </div>`;
      }
      return html;

    },
    getLotImageBackground: function (params) {
      return process.env.VUE_APP_server_URL + 'uploads/lots/' + params;
    },
    getWinnerLots: function () {
      this.auction.winnerLots = window._.filter(this.auction.all_lots, function (lot) {
        return lot.closed > 0 && lot.bids && lot.bids.length > 0;
      });
    },
    replaceLot: function (newLotData) {
      let lotIndex = window._.findIndex(this.auction.all_lots, {
        id: newLotData.id
      });

      if (lotIndex > -1) {
        this.auction.all_lots.splice(lotIndex, 1, newLotData);
      }
    },
    allLotsClosed: function (params) {
      let result = window._.find(this.auction.all_lots, function (l) {
        return l.closed == 0;
      });
    },
    showCurrentOfflineLot: function (lot) {
      this.setOfflineLot(lot);
      this.active_lots.push(lot);
      if (this.auction.type == 'loud') {
        this.active_tab = 'tab_offline_bid';
      } else {
        this.active_tab = 'bids';
      }

    },
    setOfflineLot: function (lot) {

      this.offline_bids.lot = lot;
      this.offline_bids.lotId = lot.id;

      let index = window._.findIndex(this.auction.all_lots, {
        'id': lot.id
      });

      this.offline_bids.hasPreviousLot = index > 0;
      this.offline_bids.hasNextLot = index < this.auction.all_lots.length - 1;
      this.loading = false;
    },
    loadLot: function (auction_slug, bid_id) {

      let url = process.env.VUE_APP_api_server_URL + 'v1/auctions/' + auction_slug + '/lot/' + bid_id;

      this.axios.get(url)
        .then(response => {

          this.replaceLot(response.data);
          this.active_lots = this.getActiveLots();
          this.offline_bids.lot = response.data;
          // let lotIndex = window._.findIndex(this.auction.all_lots, {
          //   id: response.data.lot.id
          // });

          // this.auction.all_lots[lotIndex] = this.offline_bids.lot;
          // this.placedialog = false;

        }, response => {
          // error callback
          console.log(response);
        });
    },
    updateLot: function (auction_slug, bid_id, status) {

      let url = process.env.VUE_APP_api_server_URL + 'v1/auctions/' + auction_slug + '/lot/' + bid_id;
      this.axios.get(url)
        .then(response => {
          // console.log(this.offline_bids.lotId, response.data.id, status);

          switch (status) {
            case 1:
              if (this.offline_bids.lotId == response.data.id) {
                this.offline_bids.lot = response.data;
                this.replaceLot(response.data);
                this.nextOfflineLot();
              }
              break;
            case 0:
              if (this.offline_bids.lotId != response.data.id) {
                this.offline_bids.loading = false;
                this.offline_bids.new = null;
                this.offline_bids.lot = response.data;
                this.replaceLot(response.data);
              }
              break;
            default:
              break;
          }



        }, response => {
          // error callback
          console.log(response);
        });
    },
    updateQuietLot: function (auction_slug, lot_number) {

      let langIndex = this.auction.translations.findIndex(
        x => x.locale == 'ua'
      );

      let auction_slug1 = this.auction.translations[langIndex].slug;

      let url = process.env.VUE_APP_api_server_URL + 'v1/auctions/' + auction_slug1 + '/lot/' + lot_number;
      this.axios.get(url)
        .then(response => {
          this.replaceLot(response.data);
        }, response => {
          // error callback
          console.log(response);
        });
    },
    getActiveLots: function () {
      let lots = window._.filter(this.auction.all_lots, function (lot) {
        return lot.closed != 1;
      });
      return lots;
    },
    nextOfflineLot: function () {
      if (!this.auction || window._.isEmpty(this.auction.all_lots)) return;

      if (this.offline_bids && this.offline_bids.lotId) {
        let nextLotIndex = window._.findIndex(this.auction.all_lots, {
          'id': this.offline_bids.lotId
        }) + 1;
        if (nextLotIndex < this.auction.all_lots.length) {
          this.setOfflineLot(this.auction.all_lots[nextLotIndex]);
        }
      }
    },
    previousOfflineLot: function () {
      if (!this.auction || window._.isEmpty(this.auction.all_lots)) return;

      if (this.offline_bids && this.offline_bids.lotId) {
        let prevLotIndex = window._.findIndex(this.auction.all_lots, {
          'id': this.offline_bids.lotId
        }) - 1;
        if (prevLotIndex >= 0) {
          this.setOfflineLot(this.auction.all_lots[prevLotIndex]);
        }
      }
    }

  }
};
