<template>
  <div id="pageCard">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg4 sm12 v-for="(auction) in auctions" v-bind:key="auction.id">
          <v-card
            height="400px"
            width="250px"
            :to="{ name: 'Auction', params: { slug: auction.slug }}"
            class="ma-0"
          >
            <v-img :src="getAuctionImageBackground(auction.image)" height="250px"></v-img>
            <v-card-text>
              <div>
                Назва: {{auction.title}}
                <br>
                Дата початку: {{ auction.start_date}}
              </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn flat small>{{getAuctionTypeName(auction.type)}}</v-btn>
            </v-card-actions>
            <!-- <placeBid ref="placeBid" :lot_number="null"></placeBid> -->
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import VWidget from '@/components/VWidget';
import mixin from '@/mixins/auction';
// import placeBid from '@/components/bid/dialogs/placeBid';
export default {
  components: {
    VWidget,
    // placeBid
  },
  mixins: [mixin],
  props: {
    auctions: {
      type: Array
    },
  },
  data () {
    return {
      show: true,
    };
  },
  computed: {
  },
  methods: {
    addBid: function () {
      // this.member = [];
      this.$refs.placeBid.openDialog();

    },
    link: function (id) {
      this.$router.push({ path: '/auction/12' });
    },
  }
};
</script>
<style lang="stylus" scoped>
.mt-45 {
  margin-top: -45px;
}

.mt-56 {
  margin-top: -56px;
}
</style>
