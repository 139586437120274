<template>
  <v-dialog v-model="editdialog" max-width="500px">
    <v-card>
      <v-card-text>
        <v-flex xs12 sm12 md6>
          <v-text-field v-if="member.mobile_phone" v-model="member.mobile_phone"></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md12>
          <v-text-field label="Призвище" v-model="member.first_name" required></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md12>
          <v-text-field label="Ім'я" v-model="member.last_name"></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md12>
          <v-text-field label="Email" v-model="member.email"></v-text-field>
        </v-flex>
        <v-flex xs12 sm12 md12>
          <v-select
            v-model="member.pivot.location"
            :items="locations"
            item-text="name"
            item-value="value"
            label="Локація"
          ></v-select>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-btn depressed left color="primary" @click.native="ok()">Ок</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed color="normal" @click.native="editdialog = false">Відміна</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixin from "@/mixins/auction";
export default {
  mixins: [mixin],
  store: ["auction", "reload_data"],
  data() {
    return {
      locations: [
        { name: "Интернет", value: 0 },
        { name: "У залі", value: 1 },
        { name: "По телефону", value: 2 },
        { name: "Заочний бід", value: 3 }
      ],
      editdialog: null,
      loading: true,
      next_number: 0,
      member: {
        mobile_phone: null
      },
      mobile_phone: null
    };
  },
  computed: {
    // showDialog: function (params) {
    //   return this.dialog;
    // }
  },
  watch: {
    // openDialog: function () {
    //   this.activateDialog = true;
    // }
  },
  methods: {
    openDialog: function(member) {
      this.member = member;
      this.editdialog = true;
    },
    returnBoolean: function(value) {
      if (value) return true;
      return false;
    },

    ok: function() {
      if (this.canSave() === false) {
        return;
      }
      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/mypages/updateMemberLocation/" +
        this.auction.id +
        "/" +
        this.member.id;
      let data = this.member;

      this.axios.post(url, data).then(
        response => {
          // console.log(response);
          this.editdialog = false;
          this.reload_data = true;
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    },
    canSave: function() {
      let result =
        this.validatePhone(this.member.mobile_phone) &&
        this.member.mobile_phone &&
        this.member.first_name &&
        this.member.last_name;
      if (result) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
