<template>
  <div id="pageTab" v-if="auction && !loading">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12>
          <div slot="widget-content">
            <v-tabs icons-and-text centered color="grey lighten-2" v-model="active_tab">
              <v-tabs-slider color="red"></v-tabs-slider>
              <v-tab href="#registration" color="black">Реєстрація</v-tab>
              <v-tab href="#tab_offline_bid" v-if="auction.type=='loud'">Активний лот</v-tab>
              <v-tab href="#bids">Ставки</v-tab>
              <v-tab href="#lots">Лоти</v-tab>
              <v-tab href="#activemembers">Активні учасники</v-tab>
              <v-tab href="#winners">Переможці</v-tab>
              <v-tab href="#params" v-if="auction.type!='loud'">Параметри аукціона</v-tab>
              <v-tab-item id="registration">
                <v-card flat>
                  <RegistrationList :members="members" v-if="active_tab =='registration'"></RegistrationList>
                </v-card>
              </v-tab-item>
              <v-tab-item id="tab_offline_bid" v-if="auction.type == 'loud'">
                <v-card flat>
                  <activeLot v-if="active_tab =='tab_offline_bid'"></activeLot>
                </v-card>
              </v-tab-item>
              <v-tab-item id="bids">
                <v-card flat>
                  <bids-list :bids="bidsList" v-if="active_tab =='bids'"></bids-list>
                </v-card>
              </v-tab-item>
              <v-tab-item id="lots">
                <v-card flat>
                  <LotsList :lots="auction.all_lots" v-if="active_tab =='lots'"></LotsList>
                </v-card>
              </v-tab-item>
              <v-tab-item id="activemembers">
                <v-card flat>
                  <ActiveMembers v-if="active_tab =='activemembers'"></ActiveMembers>
                </v-card>
              </v-tab-item>
              <v-tab-item id="winners">
                <v-card flat>
                  <winners v-if="active_tab =='winners'"></winners>
                </v-card>
              </v-tab-item>
              <v-tab-item id="params">
                <v-card flat>
                  <params v-if="active_tab =='params' && auction.type != 'loud'"></params>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </div>
        </v-flex>
      </v-layout>
    </v-container>
    <socket></socket>
  </div>
</template>

<script>
import VWidget from "@/components/VWidget";
import RegistrationList from "@/components/auction/AuctionRegistrationList";
import LotsList from "@/components/lot/AuctionLotsList";
import bidsList from "@/components/bid/bidsList";
import ActiveMembers from "@/components/auction/AuctionActiveMembersList";
import winners from "@/components/auction/AuctionWinnersList";
import activeLot from "@/components/lot/activeLot";
import params from "@/components/auction/Params";
import mixin from "@/mixins/lot";
import Socket from "@/components/socketConnector";
import mixin3 from "@/mixins/members";
export default {
  store: [
    "auction",
    "reload_data",
    "active_lots",
    "offline_bids",
    "active_tab",
    "members",
    "reload_members"
  ],
  components: {
    VWidget,
    RegistrationList,
    LotsList,
    ActiveMembers,
    bidsList,
    activeLot,
    winners,
    Socket,
    params
  },
  mixins: [mixin, mixin3],
  data() {
    return {
      // current_tab: this.active_tab,
      loading: false,
      loadData: false
    };
  },
  computed: {
    bidsList: function() {
      let result = window._.filter(this.auction.all_lots, function(o) {
        return !window._.isEmpty(o.bids);
      });
      return result;
    }
  },
  watch: {
    reload_data: function(val) {
      if (this.reload_data) {
        this.auction = [];
        this.getAuction(this.$route.params.slug);
      }
    },
    reload_members: function(val) {
      if (this.reload_members) {
        this.members = [];
        this.getMembersList(this.$route.params.slug);
      }
    }
  },
  created: function() {
    if (typeof this.$route.params.slug === "undefined") {
      this.$router.push("/auctions/active");
      this.loadData = false;
    } else {
      this.loadData = true;
    }
  },
  mounted: function() {
    // if (this.loadData) {
    this.getAuction(this.$route.params.slug);
    // }
  },
  methods: {
    getAuction: function(slug) {
      this.loading = true;
      // GET /someUrl
      this.axios
        .get(
          process.env.VUE_APP_api_server_URL + "v2/auctions/" + slug + "/all"
        )
        .then(
          response => {
            // get body data
            let result = response.data;
            this.auction = result;
            this.members = this.auction.members;
            this.reload_data = false;
            this.active_lots = this.getActiveLots();
            this.loading = false;

            if (this.auction.type == "loud") {
              if (!this.auction.closed) {
                this.active_tab = "tab_offline_bid";
              }
              if (this.auction.closed) {
                this.active_tab = "lots";
              }
            }
          },
          response => {
            // error callback
          }
        );
    },
    getActiveMembers: function() {
      let result = window._.filter(this.bidsList.bids, function(o) {
        return !window._.isEmpty(o.bids);
      });
      return result;
    }
  }
};
</script>
