<template>
  <v-form ref="form" @submit.prevent="submit">
    <v-dialog v-model="addMemberDialog" max-width="500px">
      <v-card>
        <v-card-text>
          <v-flex xs12 sm12 md6>
            <vue-tel-input
              v-model="mobile_phone.number"
              @onInput="checkMember"
              :preferredCountries="['ua']"
            ></vue-tel-input>
          </v-flex>
        </v-card-text>
        <v-card-text v-show="!loading && mobile_phone.isValid">
          <v-flex xs12 sm12 md12>
            <v-text-field
              label="Номер учасника в аукціоні"
              v-model="form.pivot.number"
              mask="####"
              :rules="[rules.required]"
              type="number"
            ></v-text-field>
            <small
              class="grey--text"
            >Якщо вказаний номер вже використовується, користувача не буде активавано.</small>
          </v-flex>

          <v-flex xs12 sm12 d-flex></v-flex>
          <v-flex xs12 sm12 md12>
            <v-text-field
              label="Призвище"
              v-model="form.first_name"
              required
              :disabled="returnBoolean(form.id)"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-text-field
              label="Ім'я"
              v-model="form.last_name"
              :disabled="returnBoolean(form.id)"
              :rules="[rules.required]"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-text-field
              label="Email"
              v-model="form.email"
              :disabled="returnBoolean(form.id)"
              :rules="[rules.required, rules.email]"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm12 md12>
            <v-select
              v-model="form.pivot.location"
              :items="locations"
              item-text="name"
              item-value="value"
              label="Локація"
              default="1"
              :rules="[rules.location]"
            ></v-select>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="!formIsValid" depressed left color="primary" @click.native="ok()">Ок</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="normal" @click.native="addMemberDialog = false">Відміна</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
import mixin from "@/mixins/auction";
import VueTelInput from "vue-tel-input";
export default {
  components: {
    VueTelInput
  },
  mixins: [mixin],
  store: ["auction", "reload_data"],
  data() {
    const defaultForm = Object.freeze({
      id: null,
      mobile_phone: null,
      pivot: {
        number: null
      }
    });
    return {
      addMemberDialog: false,
      loading: true,
      next_number: 0,
      mobile_phone: { isValid: false, number: null },
      form: Object.assign({}, defaultForm),
      rules: {
        required: value => !!value || "Required.",
        location: value => value != null || "Required.",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        }
      },
      defaultForm,
      locations: [
        // { name: 'Интернет', value: 0 },
        { name: "У залі", value: 1 },
        { name: "По телефону", value: 2 },
        { name: "Заочний бід", value: 3 }
      ]
    };
  },
  computed: {
    formIsValid() {
      return (
        this.form.pivot.number &&
        this.form.mobile_phone &&
        this.form.first_name &&
        this.form.last_name &&
        window._.isNumber(this.form.pivot.location)
      );
    }
  },
  watch: {
    // showDialog: function (val) {
    //   console.log('val=' + val);
    //   // this.init();
    //   // this.addDialog = val;
    // }
  },
  methods: {
    openDialog: function(params) {
      this.mobile_phone.number = null;
      this.mobile_phone.isValid = false;
      this.resetForm();
      this.addMemberDialog = true;
    },
    closeDialog: function(params) {
      this.addMemberDialog = false;
    },
    resetForm() {
      this.form = Object.assign({}, this.defaultForm);
      this.$refs.form.reset();
    },
    checkMember: function(number) {
      if (number.isValid) {
        this.mobile_phone.isValid = number.isValid;

        this.loading = true;

        // GET /someUrl
        this.axios
          .get(
            process.env.VUE_APP_api_server_URL +
              "v1/member/getbyphone/" +
              number.number.replace(/ /g, "")
          )
          .then(
            response => {
              // get body data
              if (response.data) {
                let newMember = response.data;
                newMember.pivot = this.form.pivot;
                this.form = newMember;
                this.form.mobile_phone = number.number;
              } else {
                this.resetForm();
                this.form.mobile_phone = number.number;
              }
              this.loadNextMemberNumber(this.auction.id);
            },
            response => {
              // error callback
            }
          );
      } else {
        this.mobile_phone.isValid = number.isValid;
        this.form.id = null;
        this.resetForm();
      }
    },
    loadNextMemberNumber: function(auction_id) {
      this.axios
        .get(
          process.env.VUE_APP_api_server_URL +
            "v1/mypages/" +
            this.auction.id +
            "/getNextMemberNumber"
        )
        .then(
          response => {
            // get body data
            this.form.pivot.number = response.data.next_number;
            this.next_number = response.data.next_number;
            this.loading = false;
          },
          response => {
            // error callback
          }
        );
    },
    ok: function() {
      if (this.$refs.form.validate()) {
        let url =
          process.env.VUE_APP_api_server_URL +
          "v1/mypages/" +
          this.auction.id +
          "/setRegisterMember";
        let data = this.form;

        this.axios.post(url, data).then(
          response => {
            // console.log(response);
            this.addMemberDialog = false;
            this.reload_data = true;
          },
          response => {
            // error callback
            // console.log(response);
          }
        );
      }
    }
  }
};
</script>
