<template>
  <v-card>
    <v-toolbar card dense color="transparent">
      <v-toolbar-title>
        <h4></h4>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>more_vert</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <template>
        <v-data-table
          :headers="headers"
          :items="lots"
          hide-actions
          :item-key="number"
          disable-initial-sort="true"
          class="elevation-0 table-striped"
        >
          <template slot="items" slot-scope="props">
            <tr>
              <td class="title">{{ props.item.number }}</td>
              <td @click="l(props.item)">
                <div class="post--media">
                  <img
                    v-lazy="getLotImgSrc(props.item)"
                    alt
                    height="100px"
                    width="100px"
                    class="image-scale"
                  />
                </div>
              </td>
              <td class="text-xs-left" v-html="getLotInfo(props.item)"></td>
              <!-- <td class="text-xs-left" v-if="!lotLastBid(props.item)">
                <v-chip outline v-if="!props.item.closed">Немає ставок</v-chip>
                <v-chip outline v-if="props.item.closed && !props.item.sell_price">Не продано</v-chip>
              </td>-->
              <td class="text-xs-left">
                <bid-info :lot="props.item"></bid-info>
              </td>
              <td>
                <v-btn
                  :color="activeColor(props.item.online_enabled == 1)"
                  @click="toggleOnlineDisabled(props.item.number)"
                  :disabled="props.item.closed == 1"
                >Online</v-btn>
                <v-btn
                  v-if="props.item.online_enabled != 1 && props.item.closed != 1"
                  color="error"
                  @click="toggleLot(props.item)"
                >Закрыть</v-btn>
                <v-btn
                  v-if="props.item.online_enabled != 1 && props.item.closed == 1"
                  color="info"
                  @click="toggleLot(props.item)"
                >Открыть</v-btn>
              </td>
              <td class="text-xs-left" v-if="auction.type == 'loud'">
                <v-icon
                  x-large
                  class="mr-2"
                  @click="showCurrentOfflineLot(props.item)"
                  v-if="props.item.closed"
                >pageview</v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
    </v-card-text>
    <placeBid ref="placeBid" :lot="lot" v-if="!this.auction.closed"></placeBid>
  </v-card>
</template>

<script>
import mixin from "@/mixins/lot";
import placeBid from "@/components/bid/dialogs/placeBid";
import bidInfo from "@/components/bid/bidInfo";
export default {
  store: [
    "auction",
    "reload_data",
    "offline_bids",
    "active_lots",
    "active_tab"
  ],
  components: {
    placeBid,
    bidInfo
  },
  mixins: [mixin],
  props: {
    lots: {
      type: Array
    }
  },
  data() {
    return {
      lot: null,
      headers: [
        {
          // text: '#',
          align: "left",
          sortable: true,
          value: "id"
        },
        { text: "Фото", sortable: false },
        { text: "Лот", sortable: false },
        { text: "Поточна ставка" },
        { text: "-", sortable: false }
      ],
      colors: {
        processing: "blue",
        sent: "red",
        delivered: "green"
      }
    };
  },
  computed: {
    // randomColor () {
    //   let item = Math.floor(Math.random() * this.colors.length);
    //   return this.colors[item];
    // },
  },
  methods: {
    activeColor(bool) {
      if (bool) {
        return "success";
      }
      return "gray";
    },
    l: function(lot) {
      if (this.auction.type != "loud" && this.auction.closed == 0) {
        this.lot = lot;
        this.addBid();
      }
    },
    addBid: function() {
      // this.member = [];
      this.$refs.placeBid.openDialog();
    },
    // getColorByStatus (status) {
    //   return this.colors[status];
    // },
    getLotImgSrc: function(item) {
      return process.env.VUE_APP_server_URL + item.thumb_dashboard;
    },
    getLotBidsInfo(item) {
      return "Номер телефону:" + item.mobile_phone + "<br> Email:" + item.email;
    },
    toggleOnlineDisabled: function(lot_number) {
      if (lot_number == null) return;

      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/" +
        this.auction.slug +
        "/lot/" +
        lot_number +
        "/toggleOnlineDisabled";

      this.axios.post(url).then(
        response => {
          this.replaceLot(response.data.lot);
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    },
    toggleLot(lot) {
      if (lot.closed == 0) {
        return this.closeOfflineLot(lot.number);
      }
      return this.openOfflineLot(lot.number);
    },
    closeOfflineLot: function(lot_number) {
      if (lot_number == null) return;

      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/" +
        this.auction.slug +
        "/lot/" +
        lot_number +
        "/close";

      this.axios.post(url).then(
        response => {
          this.replaceLot(response.data.lot);
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    },
    openOfflineLot: function(lot_number) {
      if (lot_number == null) return;

      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/" +
        this.auction.slug +
        "/lot/" +
        lot_number +
        "/open";

      this.axios.post(url).then(
        response => {
          this.replaceLot(response.data.lot);
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    }
  }
};
</script>
