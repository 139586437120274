<template>
  <div id="pageTable">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12>
          <v-card>
            <v-toolbar card color="white">
              <v-text-field
              flat
              solo
              prepend-icon="search"
              placeholder="Type something"
              v-model="search"
              hide-details
              class="hidden-sm-and-down"
              ></v-text-field>     
              <v-btn icon>
                <v-icon>filter_list</v-icon>
              </v-btn>         
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="complex.headers"
                :search="search"
                :items="auctions"
                class="elevation-0 table-striped"
                :disable-initial-sort="disablesort"

                >
                <template slot="items" slot-scope="props">
                  <router-link tag="tr" :to="{ name: 'Auction', params: { slug: props.item.slug }}">
                    <td>{{ props.item.id }}</td>
                    <td>{{ props.item.title }}</td>
                    <td>{{ getAuctionTypeName(props.item.type) }}</td>
                    <td>{{ props.item.start_date }}</td>
                  </router-link>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-flex>  
        

      </v-layout>
    </v-container>
  </div>
</template>

<script>
import mixin from '@/mixins/auction';
export default {
  mixins: [mixin],
  props: {
    auctions: {
      type: Array
    },
  },
  data () {
    return {
      pagination: {
        // sortBy: 'start_date',
        // descending: true, 
        rowsPerPage: 15
      },
      disablesort: true,
      search: '',
      complex: {
        selected: [],
        headers: [
          {
            text: 'Id',
            value: 'id',
            sortable: false,
          },
          {
            text: 'Назва',
            value: 'title',
            sortable: false,
          },
          {
            text: 'Тип',
            value: 'type',
            sortable: true,
          },
          {
            text: 'Дата початку',
            value: 'start_date',
            sortable: true,
          },
        ],
      },
    };
  },
  mounted () {
    // console.log(this.auctions);
  },
};
</script>
