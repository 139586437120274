<template>
  <v-card v-if="auction">
    <v-toolbar card dense color="transparent">
      <v-toolbar-title>
        <h4></h4>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>more_vert</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text class="pa-0">
      <template>
        <v-data-table :headers="headers" :items="getWinnerList" :expand="expand" item-key="id">
          <template v-slot:items="props">
            <tr @click="props.expanded = !props.expanded">
              <td
                class="text-xs-left text-uppercase font-weight-medium"
              >#{{props.item.pivot.number}}</td>
              <td
                class="text-xs-left text-uppercase font-weight-medium"
                v-html="getContactInfo(props.item)"
              ></td>
            </tr>
          </template>
          <template v-slot:expand="props">
            <v-card flat>
              <v-data-table
                :headers="sheaders"
                :items="getWinnerLotsById(props.item.id)"
                hide-actions
                class="elevation-10"
              >
                <template slot="items" slot-scope="props">
                  <td class="text-xs">ЛОТ №{{props.item.number}}</td>
                  <td class="text-xs">{{props.item.title}}</td>
                  <td class="text-xs">{{props.item.sell_price}}</td>
                </template>
                <!-- <template slot="footer">
                  <td colspan="100%">
                    <strong>Загалом: {{ getWinnerLotsSummById(prop.item.id) }}</strong>
                  </td>
                </template>-->
              </v-data-table>
            </v-card>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
    </v-card-text>
  </v-card>
</template>
<script>
import mixin from "@/mixins/lot";
export default {
  mixins: [mixin],
  store: ["auction"],
  data() {
    return {
      expand: false,
      headers: [
        {
          text: "Номер",
          align: "left",
          sortable: true
        },
        { text: "Контактні данні" }
      ],
      sheaders: [
        {
          text: "Номер"
        },
        { text: "Заголовок" },
        { text: "Сума" }
      ],
      colors: {
        processing: "blue",
        sent: "red",
        delivered: "green"
      }
    };
  },
  computed: {
    randomColor() {
      let item = Math.floor(Math.random() * this.colors.length);
      return this.colors[item];
    },
    winnerLots() {
      let winnerLots = [];
      if (this.auction) {
        winnerLots = window._.filter(this.auction.all_lots, function(lot) {
          return lot.closed > 0 && lot.bids && lot.bids.length > 0;
        });
      }
      return winnerLots;
    },
    getWinnerList() {
      let list = this.getWinnerId();
      let result = [];
      list.forEach(item => {
        if (item) {
          let info = this.getWinnerInfoById(item);

          result.push(info);
        }
      });

      return result;
    }
  },
  methods: {
    getWinnerId: function() {
      let winners = window._.filter(this.auction.all_lots, function(lot) {
        return lot.closed > 0 && lot.bids.length > 0;
      });
      winners = window._.map(winners, function(item) {
        return item.winner && item.winner.id;
      });
      winners = window._.uniq(winners);

      return winners;
    },

    getWinnerLotsById: function(id) {
      console.log(id);
      let lots = this.winnerLots;
      let info = window._.filter(lots, function(lot) {
        return lot.winner && lot.winner.id == id;
      });
      return info;
    },
    getWinnerInfoById: function(id) {
      let firstLot = this.getWinnerLotsById(id);
      if (firstLot) {
        let info = firstLot[0].winner;
        return info;
      }
      return null;
    },
    getColorByStatus(status) {
      return this.colors[status];
    },
    getContactInfo(item) {
      return (
        item.first_name +
        " " +
        item.last_name +
        "<br> Номер телефону: " +
        item.mobile_phone +
        " <br> Email: " +
        item.email
      );
    },
    getWinnerLotsSummById: function(id) {
      let lots = this.getWinnerLotsById(id);
      return window._.sumBy(lots, function(o) {
        return o.sell_price;
      });
    },
    expandedCSS: function(status) {
      // console.log(status);
      if (status) {
        return "selected_lot";
      } else {
        return "";
      }
    }
  }
};
</script>
<style>
.selected_lot {
  background-color: #c0c0c0;
}
</style>

