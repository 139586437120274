<template>
  <div>
    <span v-if="value">
      <strong class="title" v-if="!lot.closed">{{ value}}</strong>
      <span class="title" v-if="lot.closed && lot.sell_price">Продано за {{lot.sell_price }}</span>
    </span>
    <div class="title" v-if="value">Номер: {{member_number}}</div>
    <div v-if="value">
      <small>Дата: {{ created_at}}</small>
    </div>
    <div v-if="!value">
      <v-chip outline v-if="!lot.closed">Немає ставок</v-chip>
      <v-chip outline v-if="lotclosed && !lot.sell_price">Не продано</v-chip>
    </div>
  </div>
</template>

<script>

import mixin from '@/mixins/lot';
export default {
  mixins: [mixin],
  props: {
    lot: {
      type: Object
    },
  },
  data () {
    return {
    };
  },
  computed: {
    value () {
      if (this.lotLastBid(this.lot)) {
        return this.lotLastBid(this.lot).value;
      }
      return '';
    },
    member_number () {
      if (this.lotLastBid(this.lot)) {
        return this.lotLastBid(this.lot).member.pivot.number;
      } else {
        return null;
      }
    },
    created_at () {
      if (this.lotLastBid(this.lot)) {
        return this.lotLastBid(this.lot).created_at;
      } else {
        return null;
      }
    }
  },
  methods: {
  }
};
</script>
