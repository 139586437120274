<template>
  <v-dialog v-model="activateDialog" max-width="500px">
    <v-card>
      <v-card-text>
        <v-flex xs12 sm12 md12>
          <v-text-field label="Номер учасника в аукціоні" v-model="member.pivot.number" mask="####"></v-text-field>
          <small
            class="grey--text"
          >Якщо вказаний номер вже використовується, користувача не буде активавано.</small>
        </v-flex>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="canSave()" depressed left color="primary" @click.native="ok()">Ок</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed color="normal" @click.native="activateDialog = false">Відміна</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import mixin from "@/mixins/auction";
export default {
  mixins: [mixin],
  store: ["auction"],
  props: {
    member: Object
  },
  data() {
    return {
      activateDialog: null,
      loading: true,
      next_number: 0
    };
  },
  computed: {
    // showDialog: function (params) {
    //   return this.dialog;
    // }
  },
  watch: {
    // openDialog: function () {
    //   this.activateDialog = true;
    // }
  },
  methods: {
    openDialog: function() {
      this.activateDialog = true;
      this.loadNextMemberNumber(this.auction.id);
    },
    returnBoolean: function(value) {
      if (value) return true;
      return false;
    },
    loadNextMemberNumber: function(auction_id) {
      this.axios
        .get(
          process.env.VUE_APP_api_server_URL +
            "v1/mypages/" +
            this.auction.id +
            "/getNextMemberNumber"
        )
        .then(
          response => {
            // get body data
            this.member.pivot.number = response.data.next_number;
            this.next_number = response.data.next_number;
            this.loading = false;
          },
          response => {
            // error callback
          }
        );
    },
    ok: function() {
      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/mypages/" +
        this.auction.id +
        "/setActivateMember";
      let data = this.member;

      this.axios.post(url, data).then(
        response => {
          // console.log(response);
          this.activateDialog = false;
          this.reload_data = true;
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    },
    canSave: function(params) {
      let result =
        this.loading &&
        this.member.pivot.number &&
        window._.isNumeric(this.member.pivot.number);

      if (result == null) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
