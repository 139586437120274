<template>
  <v-card>
    <v-toolbar color="transparent" flat dense card>
      <v-toolbar-title class="subheading ft-200"></v-toolbar-title>
    </v-toolbar>
    <!-- Auction not started -->
    <v-layout row>
      <v-responsive>
        <v-container fill-height>
          <v-layout align-center>
            <v-flex>
              <h3 class="display-3">{{auction.title}}</h3>

              <span class="subheading">{{ auction.type}}.</span>

              <v-divider class="my-3"></v-divider>

              <div class="title mb-3">{{ auction.start_date}}</div>

              <v-btn
                class="mx-0"
                dark
                large
                @click="switchAuction()"
                :disabled="auction.type!='quiet'"
              >Змінити тип</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-responsive>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  store: ["auction"],
  components: {},
  data() {
    return {
      loading: true,
      interval: null
    };
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    switchAuction: function() {
      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/auction/" +
        this.auction.slug +
        "/switch";

      this.axios.get(url).then(
        response => {
          // console.log(response);
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    }
  }
};
</script>
<style scoped>
.ft-200 {
  font-weight: 200;
}
.post--item:hover {
  background: #f6f6f6;
}
.post--item a {
  text-decoration: none;
}
.flex-list.vertical {
  flex-direction: column;
}
.flex-list li {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.flex-list li:last-child {
  border: none;
}

.flexbox-centering {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-scale:hover {
  transform: scale(1.05);
  transition: 0.7s;
}
</style>
