<template>
  <v-card>
    <v-toolbar card dense color="transparent">
      <!-- <v-toolbar-title><h4></h4></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>more_vert</v-icon>
      </v-btn>-->
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="position: relative">
      <v-btn absolute dark fab top left color="pink" @click.native="add()" v-if="!auction.closed">
        <v-icon>person_add</v-icon>
      </v-btn>
    </v-card-text>
    <v-card-text class="pa-0">
      <template>
        <v-data-table :headers="headers" :items="members" class="elevation-0 table-striped">
          <template slot="items" slot-scope="props">
            <td v-if="props.item.pivot.status==1">{{ props.item.pivot.number }}</td>
            <td class="text-xs-left" v-else>-</td>
            <td class="text-xs-left">
              {{ props.item.title }}
              <v-icon small class="mr-2" @click="edit(props.item)" v-if="!auction.closed">edit</v-icon>
            </td>
            <td class="text-xs-left" v-html="getContactInfo(props.item)"></td>
            <td class="text-xs-left" v-if="props.item.pivot.status==0">
              <v-btn color="success" @click="activateMember(props.item)">Активувати</v-btn>
            </td>
            <td class="text-xs-left" v-else>
              <v-chip
                outline
                :color="getColorByStatus(props.item.member_type)"
              >{{props.item.member_type}}</v-chip>
            </td>
            <td class="text-xs-left">
              <v-chip
                label
                outline
                :color="getColorByStatus(props.item.member_type)"
              >{{ getMemberLocationName(props.item)}}</v-chip>
            </td>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
    </v-card-text>
    <add-dialog ref="addmemberdialog"></add-dialog>
    <activate-dialog ref="activatememberdialog" :member="member"></activate-dialog>
    <edit-member-dialog ref="editmemberdialog" v-if="editdialog"></edit-member-dialog>
  </v-card>
</template>

<script>
// import items from '@/api/order';
import AddDialog from '@/components/auction/dialogs/addMember';
import ActivateDialog from '@/components/auction/dialogs/activateMember';
import EditMemberDialog from '@/components/auction/dialogs/editMember';
import mixin from '@/mixins/members';
export default {
  components: {
    AddDialog,
    ActivateDialog,
    EditMemberDialog
  },
  mixins: [mixin],
  store: ['auction', 'reload_data'],
  props: {
    members: {
      type: Array
    },
  },
  data () {
    return {
      loading: true,
      addMemberDialog: false,
      showActivateMemberDialog: false,
      editdialog: null,
      mobile: null,
      member: {
        id: null,
        mobile_phone: null,
        pivot: {
          number: null
        }
      },
      headers: [
        {
          text: '#',
          align: 'left',
          sortable: true,
          value: 'id'
        },
        { text: 'Користувач', value: 'title', sortable: false },
        { text: 'Контактні данні', value: 'progress' },
        { text: 'Регистрация', value: 'status' },
        { text: 'Локація' },

      ],
      colors: {
        online: 'green',
        offline: 'black',
        // delivered: 'green'
      }

    };
  },
  computed: {
    randomColor () {
      let item = Math.floor(Math.random() * this.colors.length);
      return this.colors[item];
    },
  },
  methods: {
    add: function () {
      // this.member = [];
      this.$refs.addmemberdialog.openDialog();

    },
    edit: function (member) {
      this.editdialog = true;
      this.$refs.editmemberdialog.openDialog(member);
    },
    activateMember: function (member) {

      this.member = member;
      this.$refs.activatememberdialog.openDialog();
    },
    getColorByStatus (status) {
      return this.colors[status];
    },
    getContactInfo (item) {
      return item.mobile_phone + '<br> ' + item.email;
    },
  }
};
</script>
