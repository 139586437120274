<template>
  <div>
    <v-dialog v-model="placedialog" max-width="500px" :fullscreen="isMobile()">
      <v-card>
        <v-card-text>
          <v-form ref="form1" @submit.prevent="submit">
            <v-flex xs12 sm12 md12 title>{{ form1.lot.title }}</v-flex>
            <v-flex xs12 sm12 md12>
              <v-text-field height="12px" v-model="form1.member_info" readonly></v-text-field>
            </v-flex>
            <v-flex :class="className()">
              <v-text-field
                label="Номер участника"
                outline
                v-model="member_number"
                :rules="[rules.required, rules.not_online]"
                type="number"
                ref="focus"
                v-on:keyup.enter="nextField()"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm6 md6 v-show="!form1.lot_number">
              <v-text-field
                label="Лот"
                :rules="[rules.required]"
                outline
                @input="getLotByNumber()"
                @change="getLotByNumber()"
                v-model="form1.lot_number"
                mask="#####"
                :disabled="lot != null"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 v-if="!update">
              <v-text-field
                box
                :label="bidLabel()"
                v-model="bid.value"
                :rules="[rules.min_value]"
                type="number"
                ref="focus1"
                v-on:keyup.enter="nextField2()"
                :clearable="1==1"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 sm12 md12 v-else>
              <v-text-field
                box
                :label="'Ставка :' + bid.value"
                v-model="bid.value"
                :rules="[rules.min_value]"
                type="number"
                :clearable="1==1"
              ></v-text-field>
            </v-flex>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="!formIsValid"
            depressed
            left
            dark
            color
            @click.native="ok()"
          >Зробити ставку</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="normal" @click.native="placedialog = false">Відміна</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <error-info ref="errorDialog"></error-info>
  </div>
</template>

<script>
import mixin from "@/mixins/auction";
import mixin_lot from "@/mixins/lot";
import errorInfo from "./placeBidErrorInfo.vue";
export default {
  components: {
    errorInfo
  },
  mixins: [mixin, mixin_lot],
  store: ["auction", "reload_data", "offline_bids"],
  props: {
    lot: {
      type: Object
    }
  },
  data() {
    const defaultForm = Object.freeze({
      lot_number: null,
      lot: {
        title: null,
        author: null,
        min_bid_value: null
      },
      member_info: "",
      member_number: null
    });
    return {
      processing: false,
      update: false,
      updated_bid: null,
      lastBidId: null,
      lastBidIndex: null,
      valid: false,
      placedialog: false,
      loading: false,
      bid_min_value: null,
      member: null,
      error: false,
      bid: {
        value: null,
        member: null,
        member_id: null,
        memberNumber: null
      },
      form1: Object.assign({}, defaultForm),
      rules: {
        required: value => !!value || "Required.",
        min_value: value =>
          value > this.bid_min_value || "Must be >=" + this.bid_min_value,
        not_online: value => this.member_location != 0 || "Тільки OFFLINE"
      },
      defaultForm
    };
  },
  computed: {
    formIsValid() {
      return (
        this.bid.value &&
        this.bid.member &&
        this.member.pivot.location != 0 &&
        this.processing == false
      );
    },
    member_number: {
      get: function() {
        return this.form1.member_number;
      },
      set: function(value) {
        if (value) {
          // console.log('value', value);
          this.form1.member_number = value;
          this.getMemberByNumber();
        }
      }
    },
    member_location() {
      if (this.member && this.member.pivot) {
        return this.member.pivot.location;
      } else {
        return 0;
      }
    }
  },
  watch: {
    placedialog(val) {
      if (!val) return;
      requestAnimationFrame(() => {
        this.$refs.focus.focus();
      });
    },
    lot(val) {
      if (this.auction.type != "loud") {
        this.openDialog();
      }
    }
  },
  methods: {
    openDialog: function(bid) {
      this.placedialog = true;
      this.resetForm();
      if (this.lot) {
        this.form1.lot_number = this.lot.number;
        if (this.update) {
          let lastBid = bid;
          this.member_number = lastBid.member.pivot.number;
          this.getMemberByNumber();
          this.bid.value = lastBid.value;
          this.lastBidId = bid.id;
          this.bid_min_value = this.getPervBid(bid.id).value;
        } else {
          if (this.lotLastBid(this.lot)) {
            this.bid_min_value = this.lotLastBid(this.lot).value;
          } else {
            this.bid_min_value = 0;
          }
        }
        this.loading = false;
      }
    },
    bidLabel: function() {
      return "";
      if (this.bid.value) {
        let bidMemberNumber = null;
        if (this.lastBidMember()) {
          bidMemberNumber = this.lastBidMember().member.pivot.number;
        } else {
          bidMemberNumber = "NA";
        }
        return (
          this.bid_min_value +
          " - #" +
          bidMemberNumber +
          " Наступний Крок :" +
          this.form1.lot.min_bid_value
        );
      }
      return "Please enter lot number";
    },
    isMobile: function isMobile() {
      return window.innerWidth < 993;
    },
    nextField: function() {
      this.$refs.focus1.focus();
    },
    nextField2: function() {
      if (this.formIsValid) {
        this.ok();
      }
    },
    className: function(params) {
      if (!this.form1.lot_number) {
        return "xs12 sm12 md12";
      }
    },
    resetForm: function() {
      this.bid.value = null;
      this.bid.member = null;
      this.bid.member_id = null;
      this.bid.memberNumber = null;
      this.form1 = Object.assign({}, this.defaultForm);
      this.$refs.form1.reset();
    },
    getLotByNumber: function() {
      if (this.form1.lot_number) {
        let lot_number = this.form1.lot_number;
        let result = window._.find(this.auction.all_lots, function(o) {
          return o.number == lot_number;
        });
        if (result != undefined) {
          this.form1.lot.title =
            "Лот №" +
            result.number +
            " " +
            result.title +
            " " +
            (result.authors.lenght > 0 ? result.authors[0].title : "");
          this.form1.lot.min_bid_value = result.min_bid_value;

          this.bid.value = result.min_bid_value;
        } else {
          this.form1.lot.title = "";
          this.form1.lot.min_bid_value = null;
          this.bid.value = null;
        }
      } else {
        // this.resetForm();
      }
    },
    getMemberByNumber: function() {
      if (this.form1.member_number) {
        let member_number = this.form1.member_number;
        this.bid.memberNumber = this.form1.member_number;

        let result = window._.find(this.auction.members, function(o) {
          return o.pivot.number == member_number;
        });

        if (result != undefined) {
          this.member = result;
          this.bid.member = result.id;
          this.bid.member_id = result.id;
          this.form1.member_info =
            result.title + " Номер: " + result.pivot.number;
        } else {
          this.form1.member_info = "Не знайдено ";
          this.bid.member = null;
        }
      } else {
        // this.resetForm();
      }
    },
    returnBoolean: function(value) {
      if (value) return true;
      return false;
    },

    ok: function() {
      if (this.$refs.form1.validate()) {
        this.processing = true;

        if (this.update) {
          this.updateBid();
        } else {
          this.placeBid();
        }
      }
    },
    showDialog: function(params) {
      this.placedialog = params;
    },
    placeBid: function() {
      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/auction/" +
        this.auction.slug +
        "/lot/" +
        this.form1.lot_number +
        "/bid";
      let data = this.bid;

      this.axios.post(url, data).then(
        response => {
          // console.log(response);
          // $scope.auction.offlineBids.loading = false;
          // $scope.auction.offlineBids.new = null;
          // this.offline_bids.bidPlaceError = response.data.error;
          // this.offline_bids.lot = response.data.lot;
          this.replaceLot(response.data.lot);
          this.reload_bids = true;
          this.processing = false;
          this.placedialog = false;
          if (response.data.error) {
            window.getApp.$emit("APP_PLACE_BID_ERROR");
            this.$refs.errorDialog.openDialog();
          }
        },
        response => {
          // error callback
          // console.log(response);
          this.processing = false;
          window.getApp.$emit("APP_BAD_REQUEST");
        }
      );
    },
    updateBid: function() {
      let url =
        process.env.VUE_APP_api_server_URL + "v1/update-bid/" + this.lastBidId;
      let data = this.bid;

      this.axios.post(url, data).then(
        response => {
          this.offline_bids.lot.bids[this.lastBidIndex].value =
            response.data.bid.value;
          this.offline_bids.lot.bids[this.lastBidIndex].member = this.member;
          // this.replaceLot(response.data.bid.lot);
          this.placedialog = false;
          this.reload_bids = true;
          this.processing = false;
        },
        response => {
          // error callback
          // console.log(response);
          this.processing = false;
          window.getApp.$emit("APP_BAD_REQUEST");
        }
      );
    }
  }
};
</script>
