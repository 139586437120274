<template>
  <v-dialog
    v-model="placedialog"
    max-width="500px"
    :fullscreen="isMobile()"
  >
    <v-card>
      <v-container
        fluid
        grid-list-lg
      >
        <v-layout
          row
          wrap
        >
          <v-flex xs12>
            <v-card
              color="blue-grey darken-2"
              class="white--text"
            >
              <v-card-title primary-title>
                <div>
                  <div class="headline">Ставка не прийнята</div>
                  <span>Можливо ставка була перебита іншим учасників</span>
                </div>
              </v-card-title>
              <v-card-actions>
                <v-btn
                  flat
                  dark
                  @click="ok()"
                >OK</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data () {
    return {
      processing: false,
      update: false,
      updated_bid: null,
      lastBidId: null,
      lastBidIndex: null,
      valid: false,
      placedialog: null,
      loading: false,
      bid_min_value: null,
      member: null,
      error: false,
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    isMobile: function isMobile () {
      return window.innerWidth < 993;
    },
    className: function (params) {
      if (!this.form1.lot_number) {
        return 'xs12 sm12 md12';
      }
    },
    openDialog: function () {
      this.placedialog = true;
    },
    returnBoolean: function (value) {
      if (value) return true;
      return false;
    },

    ok: function () {
      this.placedialog = false;
    },
  }
};
</script>
