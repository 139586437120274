<template>
  <v-card>
    <v-toolbar
      color="transparent"
      flat
      dense
      card
      v-if="auction.started && offline_bids.lotId && active_lots.length"
    >
      <v-toolbar-title class="subheading ft-200">
        <h3
          class="headline mb-0"
          v-if="offline_bids.lotId"
        >Лот№ {{offline_bids.lot.number}} - {{offline_bids.lot.title}}</h3>
        <span
          class="text-warning"
          v-if="isOfflineLotActive() && auction.type == 'loud'"
        >Поточний активний лот</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn fab dark small>
        <v-icon dark @click="previousOfflineLot()">navigate_before</v-icon>
      </v-btn>
      <v-btn fab dark small>
        <v-icon dark @click="nextOfflineLot()">navigate_next</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <!-- Auction not started -->
    <v-layout v-if="!auction.started && !auction.closed" row>
      <v-responsive>
        <v-container fill-height>
          <v-layout align-center>
            <v-flex>
              <h3 class="display-3">{{auction.title}}</h3>

              <span class="subheading">{{ auction.summary}}.</span>

              <v-divider class="my-3"></v-divider>

              <div class="title mb-3">{{ auction.start_date}}</div>

              <v-btn class="mx-0" dark large @click="startAuction()">Розпочати аукціон</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-responsive>
    </v-layout>

    <!-- Auction started and have lots -->
    <v-layout v-else-if="active_lots.length" row wrap>
      <v-flex xs12 sm4>
        <v-card>
          <v-img
            :src="getLotImageBackground(offline_bids.lot.image)"
            height="400px"
            v-if="offline_bids != null"
          ></v-img>

          <v-card-title primary-title>
            <v-textarea solo v-html="lotInfo"></v-textarea>
          </v-card-title>

          <v-card-actions>
            <v-btn
              :color="onlineStatusColor"
              @click.native="toggleOnlineDisabled()"
              v-if="onlineStatus == true"
            >Online</v-btn>
            <v-btn
              depressed
              :color="onlineStatusColor"
              @click.native="toggleOnlineDisabled()"
              v-else
            >Offline</v-btn>
            <v-btn
              color="blue-grey"
              class="white--text"
              @click.native="closeOfflineLot()"
              v-if="!offline_bids.lot.closed"
            >Закрити лот</v-btn>
            <v-btn
              color="blue-grey"
              class="white--text"
              @click.native="openOfflineLot()"
              v-else
            >Відкрити лот</v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 sm8>
        <v-flex xs12 sm12>
          <h3
            class="headline mb-0"
          >Поточна ставка: {{ offline_bids.lot.sell_price }} - Наступний шаг: {{ offline_bids.lot.min_bid_value }}</h3>
        </v-flex>
        <v-card class="pa-0">
          <activeLotBidsList :current_lot="offline_bids.lot.number"></activeLotBidsList>
        </v-card>
      </v-flex>
    </v-layout>
    <!-- No active lots -->
    <v-layout v-else>
      <v-responsive>
        <v-container fill-height>
          <v-layout align-center>
            <v-flex>
              <h3 class="display-3">{{auction.title}}</h3>

              <span class="subheading">Немає активних лотів</span>

              <v-divider class="my-3"></v-divider>

              <div class="title mb-3">{{ auction.start_date}}</div>

              <v-btn
                class="mx-0"
                dark
                large
                @click="closeAuction()"
                v-if="!auction.closed"
              >Закрити аукціон</v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-responsive>
    </v-layout>
  </v-card>
</template>

<script>
import mixin from "@/mixins/lot";
import mixin_bids from "@/mixins/bids";
import activeLotBidsList from "@/components/lot/activeLotBidsList";

export default {
  store: [
    "auction",
    "active_lots",
    "offline_bids",
    "reload_bids",
    "reload_data"
  ],
  components: {
    activeLotBidsList
  },
  mixins: [mixin, mixin_bids],
  data() {
    return {
      loading: true,
      interval: null,
      headers: [
        {
          text: "#",
          align: "left",
          sortable: true,
          value: "id"
        },
        { text: "Фото", value: "title", sortable: false },
        { text: "Лот", value: "title", sortable: false },
        { text: "Поточна ставка", value: "progress" }
      ],
      colors: {
        processing: "blue",
        sent: "red",
        delivered: "green"
      }
    };
  },
  computed: {
    lotInfo() {
      let author_title = "";

      if (this.offline_bids.lot) {
        if (this.offline_bids.lot.authors.length != 0) {
          author_title = this.offline_bids.lot.authors[0].title;
        }
        return (
          author_title +
          this.offline_bids.lot.year +
          " р. <br>" +
          this.offline_bids.lot.summary +
          "<br> Стартова ціна: " +
          this.offline_bids.lot.start_price
        );
      } else {
        return "";
      }
    },
    onlineStatus() {
      if (
        this.offline_bids.lot.online_enabled != null &&
        this.offline_bids.lot.online_enabled != 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    onlineStatusColor() {
      if (
        this.offline_bids.lot.online_enabled != null &&
        this.offline_bids.lot.online_enabled != 0
      ) {
        return "green";
      } else {
        return "gray";
      }
    }
  },
  watch: {
    reload_bids: function(val) {
      if (this.reload_bids) {
        this.setOfflineLot(this.active_lots[0]);
        this.reload_bids = false;
      }
    }
  },
  created() {
    if (this.active_lots[0]) {
      this.setOfflineLot(this.active_lots[0]);
    }
  },
  mounted() {
    // this.interval = setInterval(function () {
    //   this.bidsRefresh();
    // }.bind(this), 5000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  methods: {
    getColorByStatus(status) {
      return this.colors[status];
    },
    getLotImgSrc: function(item) {
      return process.env.VUE_APP_server_URL + item.preview_url;
    },
    haveLots: function() {
      if (this.active_lots) {
        return true;
      } else {
        return false;
      }
    },
    isOfflineLotActive: function() {
      if (
        !this.offline_bids ||
        !this.offline_bids.lot ||
        window._.isEmpty(this.auction.activeLots)
      )
        return false;

      let firstActiveLot = window._.first(this.auction.activeLots);
      return firstActiveLot.id == this.offline_bids.lotId;
    },
    startAuction: function() {
      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/auction/" +
        this.auction.slug +
        "/start";

      this.axios.post(url).then(
        response => {
          // console.log(response);
          this.auction.started = 1;
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    },
    closeAuction: function(params) {
      console.log("close auction");
    },
    toggleOnlineDisabled: function() {
      if (this.offline_bids.lotId == null) return;

      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/" +
        this.auction.slug +
        "/lot/" +
        this.offline_bids.lot.number +
        "/toggleOnlineDisabled";

      this.axios.post(url).then(
        response => {
          // console.log(response);
          this.offline_bids.loading = false;
          this.offline_bids.bidPlaceError = response.data.error;
          this.offline_bids.lot = response.data.lot;
          this.replaceLot(response.data.lot);
          // this.reload_data = true;
          // $scope.applyAuction();
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    },
    closeOfflineLot: function(status) {
      if (this.offline_bids.lotId == null) return;

      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/" +
        this.auction.slug +
        "/lot/" +
        this.offline_bids.lot.number +
        "/close";

      this.axios.post(url).then(
        response => {
          // console.log(response);
          this.offline_bids.loading = false;
          this.offline_bids.new = null;
          this.offline_bids.lot = response.data.lot;
          this.replaceLot(response.data.lot);
          this.nextOfflineLot();

          // this.reload_data = true;
          // $scope.applyAuction();
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    },
    openOfflineLot: function() {
      if (this.offline_bids.lotId == null) return;
      // console.log(this.allLotsClosed());
      let url =
        process.env.VUE_APP_api_server_URL +
        "v1/" +
        this.auction.slug +
        "/lot/" +
        this.offline_bids.lot.number +
        "/open";

      this.axios.post(url).then(
        response => {
          // console.log(response);
          this.offline_bids.loading = false;
          this.offline_bids.new = null;
          this.offline_bids.lot = response.data.lot;
          this.replaceLot(response.data.lot);

          // this.reload_data = true;
          // $scope.applyAuction();
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    }
  }
};
</script>
<style scoped>
.ft-200 {
  font-weight: 200;
}
.post--item:hover {
  background: #f6f6f6;
}
.post--item a {
  text-decoration: none;
}
.flex-list.vertical {
  flex-direction: column;
}
.flex-list li {
  display: flex;
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}
.flex-list li:last-child {
  border: none;
}

.flexbox-centering {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-scale:hover {
  transform: scale(1.05);
  transition: 0.7s;
}
</style>
