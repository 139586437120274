<template>
  <v-card>
    <v-toolbar card dense color="transparent">
      <v-toolbar-title>
        <h4></h4>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon>
        <v-icon>more_vert</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider></v-divider>
    <v-card-text style="position: relative">
      <!-- <v-btn
        absolute
        dark
        fab
        top
        left
        color="pink"
        @click.native="addBid()"
        v-if="auction.type=='quiet'"
      >
        <v-icon>add</v-icon>
      </v-btn>-->
    </v-card-text>
    <v-card-text class="pa-0">
      <template>
        <v-data-table
          :headers="headers"
          :items="bids"
          hide-actions
          class="elevation-0 table-striped"
          expand
        >
          <template slot="items" slot-scope="props">
            <tr @click="expandRow(props)">
              <td class="title">{{ props.item.number }}</td>
              <td>
                <div class="post--media">
                  <img :src="getLotImgSrc(props.item)" alt height="100" class="image-scale" />
                </div>
              </td>
              <td class="text-xs-left" v-html="getLotInfo(props.item)"></td>
              <!-- <td class="text-xs-left" v-if="!lastBid(props.item)">
              <v-chip v-if="!props.item.closed">Немає ставок</v-chip>
              <v-chip v-if="props.item.closed && !props.item.sell_price">Не продано</v-chip>
              </td>-->
              <td class="text-xs-left">
                <bid-info :lot="props.item"></bid-info>
              </td>
              <!-- <td>{{lastBid(props.item).created_at }}</td> -->
            </tr>
          </template>
          <template slot="expand" slot-scope="props">
            <v-data-table
              :headers="headers2"
              :items="props.item.bids"
              hide-actions
              class="elevation-0 table-striped"
            >
              <template slot="items" slot-scope="props">
                <tr>
                  <td class="text-sm-left subheading">
                    <v-icon
                      small
                      class="mr-2"
                      @click="deleteBid(props.item)"
                      v-if="props.item.member.pivot.location != 0 && props.index == 0 && expandedLot.closed != 1"
                    >delete</v-icon>
                    {{ props.item.member.pivot.number }}
                  </td>
                  <td class="text-xs-left">{{ props.item.value }}</td>
                  <td class="text-xs-left hidden-sm-and-down">{{props.item.created_at}}</td>
                </tr>
              </template>
            </v-data-table>
          </template>
        </v-data-table>
      </template>
      <v-divider></v-divider>
      <placeBid ref="placeBid" :lot="lot_number" v-if="!auction.closed"></placeBid>
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "@/mixins/lot";
import placeBid from "@/components/bid/dialogs/placeBid";
import bidInfo from "@/components/bid/bidInfo";
export default {
  store: ["auction"],
  components: {
    placeBid,
    bidInfo
  },
  mixins: [mixin],
  props: {
    bids: {
      type: Array
    }
  },
  data() {
    return {
      lot_number: null,
      headers: [
        {
          // text: '#',
          align: "left",
          sortable: true,
          value: "id"
        },
        { text: "Фото", sortable: false },
        { text: "Лот", sortable: false },
        { text: "Поточна ставка" }
      ],
      headers2: [
        { text: "Номер учасника", sortable: false },
        { text: "Сума ставки", sortable: false },
        { text: "Час ставки" }
      ],
      colors: {
        processing: "blue",
        sent: "red",
        delivered: "green"
      },
      expandedLot: []
    };
  },
  computed: {
    randomColor() {
      let item = Math.floor(Math.random() * this.colors.length);
      return this.colors[item];
    }
  },
  methods: {
    expandRow(props) {
      props.expanded = !props.expanded;
      this.expandedLot = props.item;
    },
    console_log(obj) {
      console.log(obj);
    },
    addBid: function() {
      this.$refs.placeBid.openDialog();
    },
    deleteBid: function(bid) {
      let url = process.env.VUE_APP_api_server_URL + "v1/delete-bid/" + bid.id;
      let data = this.bid;

      this.axios.post(url, data).then(
        response => {
          // this.offline_bids.lot = response.data.lot;
          let lotIndex = window._.findIndex(this.auction.all_lots, {
            id: response.data.lot.id
          });
          // this.bids[lotIndex] = response.data.lot;
          this.bids.splice(lotIndex, 1, response.data.lot);

          this.placedialog = false;
          // this.reload_bids = true;
        },
        response => {
          // error callback
          console.log(response);
        }
      );
    },
    getColorByStatus(status) {
      return this.colors[status];
    },
    getLotImgSrc: function(item) {
      return process.env.VUE_APP_server_URL + item.thumb_dashboard;
    },
    getLotBidsInfo(item) {
      return "Номер телефону:" + item.mobile_phone + "<br> Email:" + item.email;
    }
  }
};
</script>
