module.exports = {
  store: ['auction', 'offline_bids', 'reload_data'],
  methods: {
    getBidsList: function (lots) {
      return window._.find(lots, function (o) {
        return o.bids.length > 0;
      });
    },
    bidStatus: function (id) {
      switch (id) {
        case 1:
          return 'Принято';
        case 2:
          return 'Не принято';

        default:
          return '';
      }
    },
    getLocationById: function (id) {
      switch (id) {
        case 0:
          return 'Online';
        case 1:
          return 'У залі';
        case 2:
          return 'За телефоном';
        case 3:
          return 'Заочний бід';

        default:
          return 'Не визначено';
      }
    },
    loadActiveBids: function () {

      if (this.pauseLoading) return true;

      let url = process.env.VUE_APP_api_server_URL + 'v1/auctions/minimal/' + this.auction.slug + '/lot/' + this.offline_bids.lot.number;

      if (this.auction.id &&
        this.offline_bids &&
        this.offline_bids.lot) {

        this.axios.get(url)
          .then(response => response.data)
          .then(response => {
            if (this.offline_bids.lot.id != response.data.id) return true;
            this.offline_bids.lot = response.data;

            let lotIndex = window._.findIndex(this.auction.all_lots, {
              id: response.data.id
            });
            this.auction.all_lots[lotIndex] = this.offline_bids.lot;
            // }

          }, response => {
            // error callback
            console.log(response);
          });
      }

    },
    bidsRefresh: function (auction_slug, lotId) {
      if (this.auction.type == 'loud') {
        this.loadActiveBids();
      } else {
        this.updateQuietLot(auction_slug, lotId);
      }

    },
    editLastBid: function (bid, index) {
      if (index == 0) this.re_selected = false;
      if (this.offline_bids.lot.closed == 0 && bid.member.pivot.location != 0 && !this.re_selected) {
        this.re_selected = true;
        return true;
      } else {
        return false;
      }
    },
  }
};
