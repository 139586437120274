<template>
  <div id="pageTab">
    <v-container grid-list-xl fluid>
      <v-layout row wrap>
        <v-flex lg12>
          <active-auction-list :auctions="items" v-if="showActive"></active-auction-list>
          <inactive-auction-list :auctions="items" v-if="!showActive"></inactive-auction-list>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
// import VWidget from '@/components/VWidget';
import ActiveAuctionList from "@/pages/auction/AuctionListCardsView";
import InactiveAuctionList from "@/pages/auction/AuctionListTableView";
export default {
  components: {
    // VWidget,
    ActiveAuctionList,
    InactiveAuctionList
  },
  data() {
    return {
      auctions: [],
      showActive: true
    };
  },
  computed: {
    items() {
      if (this.auctions) {
        return this.auctions;
      }
      return [];
    }
  },
  watch: {
    "$route.name": function(val) {
      let param = this.getRequestParam(val);
      this.getAuctionList(param);
    }
  },
  mounted: function() {
    let param = this.getRequestParam(this.$route.name);
    this.getAuctionList(param);
  },
  methods: {
    getAuctionList: function(urlParam) {
      // GET /someUrl
      this.axios
        .get(process.env.VUE_APP_api_server_URL + "v1/mypages/" + urlParam)
        .then(
          response => {
            // get body data
            let result = response.data;
            this.auctions = result;
          },
          response => {
            // error callback
            console.log(response);
          }
        );
    },
    getRequestParam: function(param) {
      let auctionFilter = this.$route.name;

      switch (auctionFilter) {
        case "AuctionsActive":
          param = "getActiveAuctions";
          this.showActive = true;
          break;
        case "AuctionsInactive":
          param = "getPreviousAuctions";
          this.showActive = false;
          break;
        default:
          param = "getActiveAuctions";
          break;
      }
      return param;
    }
  }
};
</script>
